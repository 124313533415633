<template>
    <div class="product__wrapper">
        <div
            class="product"
            itemscope
            itemtype="https://schema.org/Product"
            :class="{ '--active': showNote }"
            @mouseenter="productHover()"
            @mouseleave="removeHover()"
        >
            <meta
                itemprop="description"
                :content="$t('schema.description', { brand: name, type: getHumanProductType(product.type), price: price, delivery: processingTime })"
            />
            <div itemprop="aggregateRating" itemtype="https://schema.org/AggregateRating" itemscope>
                <meta itemprop="reviewCount" :content="$t('schema.reviewCount')" />
                <meta itemprop="ratingValue" :content="$t('schema.ratingValue')" />
            </div>

            <div itemprop="review" itemtype="https://schema.org/Review" itemscope>
                <div itemprop="author" itemtype="https://schema.org/Person" itemscope>
                    <meta itemprop="name" :content="$t('schema.review1.name')" />
                </div>
                <meta itemprop="reviewBody" :content="$t('schema.review1.body')" />
            </div>

            <div itemprop="review" itemtype="https://schema.org/Review" itemscope>
                <div itemprop="author" itemtype="https://schema.org/Person" itemscope>
                    <meta itemprop="name" :content="$t('schema.review2.name')" />
                </div>
                <meta itemprop="reviewBody" :content="$t('schema.review2.body')" />
            </div>

            <div itemprop="review" itemtype="https://schema.org/Review" itemscope>
                <div itemprop="author" itemtype="https://schema.org/Person" itemscope>
                    <meta itemprop="name" :content="$t('schema.review3.name')" />
                </div>
                <meta itemprop="reviewBody" :content="$t('schema.review3.body')" />
            </div>

            <div itemprop="review" itemtype="https://schema.org/Review" itemscope>
                <div itemprop="author" itemtype="https://schema.org/Person" itemscope>
                    <meta itemprop="name" :content="$t('schema.review4.name')" />
                </div>
                <meta itemprop="reviewBody" :content="$t('schema.review4.body')" />
            </div>

            <div itemprop="review" itemtype="https://schema.org/Review" itemscope>
                <div itemprop="author" itemtype="https://schema.org/Person" itemscope>
                    <meta itemprop="name" :content="$t('schema.review5.name')" />
                </div>
                <meta itemprop="reviewBody" :content="$t('schema.review5.body')" />
            </div>
            <meta itemprop="gtin8" :content="product.id" />
            <meta itemprop="sku" :content="product.id" />
            <div itemprop="brand" itemtype="https://schema.org/Brand" itemscope>
                <meta itemprop="name" :content="name" />
            </div>

            <div class="product__note">
                <a class="headline" :href="getLink()">
                    <span itemprop="label" class="label">
                        {{ $t('product.certificateHeadline') }}
                    </span>
                    <span itemprop="name">
                        {{ name }}
                    </span>
                </a>

                <a
                    :href="getLink()"
                    itemprop="url"
                    v-show="!blockBrandPages"
                    :class="{ '--active': learnMoreHover }"
                    @mouseenter="learnMoreHover = true"
                    @mouseleave="learnMoreHover = false"
                >
                    {{ $t('learnMore') }}
                </a>
            </div>

            <div class="product__logo-wrapper">
                <div
                    class="product__logo"
                    :class="{ '--active': learnMoreHover }"
                    @mouseenter="learnMoreHover = true"
                    @mouseleave="learnMoreHover = false"
                >
                    <a :href="getLink()" itemprop="url">
                        <img itemprop="image" :src="logo" :alt="name" v-if="logo" />
                        <strong v-else>{{ name }}</strong>
                    </a>
                </div>
            </div>

            <div class="product__info">
                <div class="product__info-price-wrapper" itemprop="offers" itemscope itemtype="https://schema.org/Offer">
                    <link itemprop="url" :href="getLink()" />
                    <meta itemprop="availability" content="https://schema.org/InStock" />
                    <span class="product__info-price" itemprop="price" :content="price">
                        {{ price }}
                    </span>
                    <span class="product__info-price" itemprop="priceCurrency">
                        €
                    </span>
                    <div class="product__info-price-vat">
                        <span>{{ $t('product.withVat') }}:</span>
                        <strong itemprop="priceWithVat" :content="priceWithVat">{{ priceWithVat }} €</strong>
                    </div>
                    <meta
                        itemprop="priceValidUntil"
                        :content="
                            $moment()
                                .add(1, 'M')
                                .locale('en')
                                .format('YYYY-MM-DD')
                        "
                    />
                </div>
                <div class="product__info-processing-time">
                    <strong style="margin-right: 0.4rem;">{{ $t('product.processingTime') }}:</strong>
                    <span class="blue-link" @click="showDeliveryInfoPopup()">
                        <span class="info-icon"></span>
                        {{ processingTime }} {{ $t('product.businessDays') }}
                    </span>
                </div>

                <div class="product__info-what-need" @click="showlightBox()">
                    {{ $t('popup.trigger', { product: name }) }}
                </div>
            </div>

            <template v-if="product.ordering == '1'">
                <!-- <span @click="showSelectModelPopup()" class="btn --primary btn-select-model" v-if="isSelectModel && product.type != 'MOTO'">
                    {{ $t('product.selectModel') }}
                </span>
                <a :href="getCheckoutLink()" :target="isPartner" class="btn --primary btn-order" v-else>
                    {{ $t('product.order') }}
                </a> -->
                <span @click="testHubSpot()" class="btn --primary btn-order">Test hubspot</span>
            </template>
        </div>
    </div>
</template>

<script>
import jsonModels from '@/data/models.json';

export default {
    name: 'Product',
    props: ['price', 'priceWithVat', 'processingTime', 'logo', 'note', 'name', 'productId', 'product'],
    components: {},
    data() {
        return {
            showNote: false,
            delayTime: 0,
            delay: '',
            blockBrandPages: false,
            spaid: false,
            partnerCode: false,
            learnMoreHover: false,
            isPartner: window.partnerCode ? '_blank' : '',
            isSelectModel: false,
            models: jsonModels,
        };
    },
    created() {
        if (window.blockBrandPages) {
            this.blockBrandPages = true;
        }

        if (window.spaid) {
            this.spaid = window.spaid;
        }

        if (window.partnerCode) {
            this.partnerCode = window.partnerCode;
        }

        if (window.selectModel) {
            this.isSelectModel = window.selectModel;
        }
    },
    mounted() {
        if (window.selectModel) {
            setTimeout(() => {
                this.isSelectModel = window.selectModel;
            }, 1000);
        }
    },
    methods: {
        testHubSpot() {
            this.$emit('unavailable', this.name, this.name);
        },
        getLink() {
            if (!this.blockBrandPages) {
                let model = this.string_to_slug(this.name);
                // return this.$t('learnMoreLink') + model + '/#brand-' + model;

                if (this.product.type.toLowerCase() == 'pkw') {
                    return this.$t('detailPageLinkPkw') + model;
                } else if (this.product.type.toLowerCase() == 'lkw') {
                    return this.$t('detailPageLinkLkw') + model;
                } else if (this.product.type.toLowerCase() == 'moto') {
                    return this.$t('detailPageLinkMoto') + model;
                }

                // return this.$t('learnMoreLink') + model;
            }
        },
        getCheckoutLink() {
            return (
                process.env.VUE_APP_BASE_URL +
                this.$i18n.locale +
                '/checkout?product=' +
                this.productId +
                (this.spaid ? '&spaid=' + this.spaid : '') +
                (this.partnerCode ? '&partner=' + this.partnerCode : '')
            );
        },
        productHover() {
            this.delay = setTimeout(() => {
                this.showNote = true;
            }, this.delayTime);
        },
        removeHover() {
            this.showNote = false;
            clearTimeout(this.delay);
        },
        showlightBox() {
            //this.lightBoxCoc = true
            //this.addLightBox()
            this.showPopup(this.product, this.name);
        },
        showDeliveryInfoPopup() {
            this.showDeliveryInfo(this.processingTime, this.name, this.getCheckoutLink());
        },
        showSelectModelPopup() {
            this.showSelectModel(this.product, this.getAvailableModels(), this.getCheckoutLink());
        },
        getAvailableModels() {
            var response = [];
            var type = 'M1';

            if (this.product.type.toLowerCase() == 'pkw') {
                type = 'M1';
            } else if (this.product.type.toLowerCase() == 'lkw') {
                type = 'N1';
            } else if (this.product.type.toLowerCase() == 'moto') {
                type = 'L';
            }

            this.models.forEach(model => {
                if (model.Make.toLowerCase() == this.product.vehicle.name.toLowerCase() && model.Type.toLowerCase() == type.toLowerCase()) {
                    response.push(model.Model);
                }
            });

            return response;
        },
    },
};
</script>

<style lang="scss" scoped>
h2 {
    font-size: 20px !important;
    line-height: 24px !important;
}

.blue-link {
    color: #1a0dab;
    cursor: pointer;
    font-weight: normal;
    display: inline-flex;
    @include transition();

    &:hover {
        text-decoration: underline;
    }
}

.product__wrapper {
    display: flex;
    position: relative;
    min-height: 355px;

    @include less-than(md) {
        min-height: 288px;
    }
}

.product {
    position: relative;
    display: flex;
    width: 100%;
    min-height: 100%;
    border: 1px solid #dedede;
    padding: 25px 16px;
    flex-direction: column;
    background: $white;
    @include box-sizing(border-box);
    @include border-radius(5px);
    @include transition();

    @include less-than(md) {
        width: 100%;
        max-width: 100%;
        padding: 10px;
    }

    &.--active {
        z-index: 60;
        //position: absolute;
        @include shadow(0, 2px, 40px, 0, rgba(0, 0, 0, 0.2));
    }
}

.product__logo-wrapper {
    width: 100%;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-bottom: 1px solid #dedede;
    padding-bottom: 10px;
    background: url($basePath+'icons/document.svg') no-repeat center right 5px;
    @include box-sizing(border-box);

    @include less-than(md) {
        height: 90px;
        background-size: 60px auto;
        background-position: center right;
    }

    a {
        color: $orange;
    }
}

.product__logo {
    width: 130px;
    height: 100%;
    display: block;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    @include opacity(0.8);
    @include transition();

    &:hover,
    &.--active {
        @include opacity(1);
    }

    @include less-than(md) {
        width: 80px;
    }

    img {
        object-fit: contain !important;
        width: 100% !important;
        height: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
    }
}

.product__info-price-wrapper {
    display: flex;
    align-items: center;
    padding-top: 30px;

    @include less-than(md) {
        padding-top: 10px;
        display: block;
    }
}

.product__info-price {
    font-weight: $fwSemi;
    font-size: 26px;
    line-height: 26px;
    white-space: nowrap;
    margin-right: 15px;
    color: $black !important;
    margin-top: 2px;

    &:first-child {
        margin-right: 5px;

        @include less-than(md) {
            margin-right: 0;
        }
    }

    @include less-than(md) {
        font-size: 22px;
        line-height: 22px;
    }
}

.product__info-price-vat {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 15px;

    @include less-than(md) {
        display: block;
        margin-top: 5px;

        strong {
            display: inline-block;
            margin-left: 5px;
        }
    }

    strong,
    span {
        font-weight: $fwSemi;
    }
}

.product__info-processing-time {
    font-weight: $fwSemi;
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 19px;
    //white-space: nowrap;
    letter-spacing: -0.4px;
    padding-right: 20px;
    position: relative;

    .info-icon {
        top: 2px;
        position: relative;
        display: inline-block;
        width: 16px;
        height: 16px;
        cursor: pointer;
        background: #1a0dab;
        margin-right: 4px;
        -webkit-mask: url($basePath+'icons/info.svg') no-repeat center;
        mask: url($basePath+'icons/info.svg') no-repeat center;
        @include transition();
    }

    @include less-than(lg) {
        white-space: normal;
    }

    @include less-than(md) {
        margin-top: 15px;
        margin-bottom: 20px;
    }

    strong {
        font-weight: $fwSemi;

        @include less-than(md) {
            display: block;
        }
    }
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    background: $green;
    color: $white;
    font-weight: $fwSemi;
    font-size: 17px;
    text-decoration: none;
    margin-top: auto;
    cursor: pointer;
    @include shadow(0, 2px, 4px, 0, rgba(0, 0, 0, 0.2));
    @include border-radius(5px);
    @include transition();

    &:hover {
        background: $greenDark;
        color: $white;
        text-decoration: none;
    }
}

.product__note {
    //margin-top: 15px;
    font-size: 14px;
    line-height: 19px;
    font-weight: $fwNormal;
    color: $black;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    padding-bottom: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid #dedede;

    a {
        color: $blue;
        text-decoration: none;
        font-size: 14px;
        display: block;
        //margin-top: 10px;

        &:hover,
        &.--active {
            text-decoration: underline;
        }
    }

    a.headline {
        display: block;
        font-weight: $fwBold;
        color: $black;
        font-size: 26px;
        line-height: 26px;
        margin-bottom: 10px;
        text-decoration: none;

        @include less-than(md) {
            font-size: 24px;
            line-height: 24px;
        }

        &:hover {
            text-decoration: none;
            color: $black;
        }

        .label {
            font-size: 12px;
            line-height: 16px;
            font-weight: $fwLight;
            display: block;
            color: rgba(0, 0, 0, 1);
            margin-bottom: 5px;
        }
    }
}

.product__info-what-need {
    margin-bottom: 20px;
    color: $blue;
    cursor: pointer;
    @include transition();

    &:hover {
        text-decoration: underline;
    }
}
</style>
