<template>
    <div class="product__wrapper-brand">
        <div class="product-brand" itemscope itemtype="https://schema.org/Product" :class="{ '--active': showNote }">
            <meta
                itemprop="description"
                :content="$t('schema.description', { brand: name, type: getHumanProductType(product.type), price: price, delivery: processingTime })"
            />
            <div itemprop="aggregateRating" itemtype="https://schema.org/AggregateRating" itemscope>
                <meta itemprop="reviewCount" :content="$t('schema.reviewCount')" />
                <meta itemprop="ratingValue" :content="$t('schema.ratingValue')" />
            </div>

            <div itemprop="review" itemtype="https://schema.org/Review" itemscope>
                <div itemprop="author" itemtype="https://schema.org/Person" itemscope>
                    <meta itemprop="name" :content="$t('schema.review1.name')" />
                </div>
                <meta itemprop="reviewBody" :content="$t('schema.review1.body')" />
            </div>

            <div itemprop="review" itemtype="https://schema.org/Review" itemscope>
                <div itemprop="author" itemtype="https://schema.org/Person" itemscope>
                    <meta itemprop="name" :content="$t('schema.review2.name')" />
                </div>
                <meta itemprop="reviewBody" :content="$t('schema.review2.body')" />
            </div>

            <div itemprop="review" itemtype="https://schema.org/Review" itemscope>
                <div itemprop="author" itemtype="https://schema.org/Person" itemscope>
                    <meta itemprop="name" :content="$t('schema.review3.name')" />
                </div>
                <meta itemprop="reviewBody" :content="$t('schema.review3.body')" />
            </div>

            <div itemprop="review" itemtype="https://schema.org/Review" itemscope>
                <div itemprop="author" itemtype="https://schema.org/Person" itemscope>
                    <meta itemprop="name" :content="$t('schema.review4.name')" />
                </div>
                <meta itemprop="reviewBody" :content="$t('schema.review4.body')" />
            </div>

            <div itemprop="review" itemtype="https://schema.org/Review" itemscope>
                <div itemprop="author" itemtype="https://schema.org/Person" itemscope>
                    <meta itemprop="name" :content="$t('schema.review5.name')" />
                </div>
                <meta itemprop="reviewBody" :content="$t('schema.review5.body')" />
            </div>
            <meta itemprop="gtin8" :content="product.id" />
            <meta itemprop="sku" :content="product.id" />
            <div itemprop="brand" itemtype="https://schema.org/Brand" itemscope>
                <meta itemprop="name" :content="name" />
            </div>

            <div class="product__logo-wrapper-brand">
                <div class="product__coc" v-if="coc">
                    <a :href="coc" target="_blank" itemprop="url" @click.prevent="showlightBox()">
                        <img itemprop="image" :src="coc" :alt="name" />
                    </a>

                    <div class="product__logo-brand" @click.prevent="showlightBox()">
                        <img itemprop="image" :src="logo" :alt="name" v-if="logo" />
                        <strong v-else>{{ name }}</strong>
                    </div>
                </div>

                <div class="product__coc-btn" @click.prevent="showlightBox()">{{ $t('product.documentPreview') }}</div>
            </div>

            <div class="product__content-brand">
                <div class="product__info">
                    <a :href="getDetailPageLink()" class="product__info-header" v-if="brandPage && !detailPage">
                        <span class="product-icon" :class="product.type.toLowerCase()"></span>
                        <div>
                            <h2 itemprop="name" class="product__content-title">
                                <span class="subhead">{{ $t('product.certificateHeadline') }}</span>
                                {{ name }}
                                {{ getProductType() }}
                            </h2>
                        </div>
                    </a>

                    <div class="product__info-header" v-else>
                        <span class="product-icon" :class="product.type.toLowerCase()"></span>
                        <div>
                            <h2 itemprop="name" class="product__content-title">
                                <span class="subhead">{{ $t('product.certificateHeadline') }}</span>
                                {{ name }}
                                {{ getProductType() }}
                            </h2>
                        </div>
                    </div>

                    <p class="note" v-if="detailPage">
                        {{
                            $t('schema.description', { brand: name, type: getHumanProductType(product.type), price: price, delivery: processingTime })
                        }}
                    </p>

                    <p class="note">
                        {{ note }}
                    </p>

                    <div class="product__info-what-need" @click="showPopUp()">
                        {{
                            $t('popup.trigger', {
                                product: name,
                                type: getProductType(),
                            })
                        }}
                    </div>

                    <div class="product__logo-wrapper-brand --mobile">
                        <div class="product__coc" v-if="coc">
                            <a :href="coc" target="_blank" itemprop="url" @click.prevent="showlightBox()">
                                <img itemprop="image" :src="coc" :alt="name" />
                            </a>

                            <div class="product__logo-brand" @click.prevent="showlightBox()">
                                <img itemprop="image" :src="logo" :alt="name" v-if="logo" />
                                <strong v-else>{{ name }}</strong>
                            </div>
                        </div>

                        <div class="product__coc-btn" @click.prevent="showlightBox()">{{ $t('product.documentPreview') }}</div>
                    </div>

                    <div class="d-flex align-items-center flex-cont">
                        <div>
                            <div class="product__info-price-wrapper" itemprop="offers" itemscope itemtype="https://schema.org/Offer">
                                <link itemprop="url" :href="getLink()" />
                                <meta itemprop="availability" content="https://schema.org/InStock" />
                                <span class="product__info-price" itemprop="price" :content="price">
                                    {{ price }}
                                </span>
                                <span class="product__info-price --currency" itemprop="priceCurrency">
                                    €
                                </span>
                                <div class="product__info-price-vat">
                                    <span>{{ $t('product.withVat') }}:</span>
                                    <strong itemprop="priceWithVat" :content="priceWithVat">{{ priceWithVat }} €</strong>
                                </div>
                                <meta
                                    itemprop="priceValidUntil"
                                    :content="
                                        $moment()
                                            .add(1, 'M')
                                            .locale('en')
                                            .format('YYYY-MM-DD')
                                    "
                                />
                            </div>
                            <div class="product__info-processing-time">
                                <strong>{{ $t('product.processingTime') }}:</strong>
                                <!-- {{ processingTime }} {{ $t('product.businessDays') }} -->
                                <span class="blue-link" @click="showDeliveryInfoPopup()">
                                    {{ processingTime }} {{ $t('product.businessDays') }}
                                    <FormLabelInfo :tooltip="false" color="blue" class="info-icon" />
                                </span>
                            </div>
                        </div>
                        <template v-if="product.ordering == '1'">
                            <span
                                @click="showSelectModelPopup()"
                                class="btn --primary flex-btn btn-select-model"
                                v-if="isSelectModel && product.type != 'MOTO'"
                            >
                                {{ $t('product.selectModel') }}
                            </span>
                            <a :href="getCheckoutLink()" class="btn --primary btn-order flex-btn" v-else>
                                {{ $t('product.order') }}
                            </a>
                        </template>

                        <!-- <a :href="getCheckoutLink()" class="btn --primary flex-btn">
                            {{ $t('product.order') }}
                        </a> -->
                    </div>
                </div>

                <div class="product__note" v-show="showNote">
                    <a class="headline" :href="getLink()" target="_blank">
                        <span itemprop="name">{{ $t('product.certificateHeadline') }}&nbsp;{{ name }}</span>
                    </a>

                    <!-- <div>
                        {{ note }}
                    </div> -->

                    <a :href="getLink()" target="_blank" itemprop="url">
                        {{ $t('learnMore') }}
                    </a>
                </div>
            </div>
        </div>

        <div class="lightbox" v-show="lightBoxCoc != ''">
            <div class="lightbox__close" @click="lightBoxCoc = ''"></div>
            <div class="lightbox__mask" @click="lightBoxCoc = ''"></div>
            <div class="lightbox__container">
                <img :src="coc" :alt="name" />
            </div>
        </div>
    </div>
</template>

<script>
import FormLabelInfo from './FormLabelInfo.vue';
import jsonModels from '@/data/models.json';

import { mapState } from 'vuex';

export default {
    name: 'Product',
    props: ['product', 'price', 'priceWithVat', 'processingTime', 'logo', 'note', 'name', 'productId', 'coc'],
    components: {
        FormLabelInfo,
    },
    data() {
        return {
            showNote: false,
            delayTime: 150,
            delay: '',
            lightBoxCoc: false,
            spaid: false,
            isSelectModel: false,
            models: jsonModels,
        };
    },
    created() {
        if (window.spaid) {
            this.spaid = window.spaid;
        }

        if (window.selectModel) {
            this.isSelectModel = window.selectModel;
        }
    },
    computed: {
        ...mapState(['brandPage', 'typePage', 'detailPage']),
    },
    mounted() {
        if (window.selectModel) {
            setTimeout(() => {
                this.isSelectModel = window.selectModel;
            }, 1000);
        }
    },
    methods: {
        getDetailPageLink() {
            let model = this.string_to_slug(this.name);

            if (this.product.type.toLowerCase() == 'moto') {
                return this.$t('detailPageLinkMoto') + model;
            } else if (this.product.type.toLowerCase() == 'lkw') {
                return this.$t('detailPageLinkLkw') + model;
            } else {
                return this.$t('detailPageLinkPkw') + model;
            }
        },
        getLink() {
            let model = this.string_to_slug(this.name);
            return this.$t('learnMoreLink') + model;
        },
        getProductType() {
            if (this.product.type == 'PKW') {
                return this.$t('categories.m1');
            } else if (this.product.type == 'LKW') {
                return this.$t('categories.n1');
            } else if (this.product.type == 'MOTO') {
                return this.$t('categories.l');
            }
        },
        getCheckoutLink() {
            return (
                process.env.VUE_APP_BASE_URL + this.$i18n.locale + '/checkout?product=' + this.productId + (this.spaid ? '&spaid=' + this.spaid : '')
            );
        },
        showlightBox() {
            //this.lightBoxCoc = true
            this.addLightBox();
        },
        showPopUp() {
            this.showPopup(this.product, this.name);
        },
        showSelectModelPopup() {
            this.showSelectModel(this.product, this.getAvailableModels(), this.getCheckoutLink());
        },
        showDeliveryInfoPopup() {
            this.showDeliveryInfo(this.processingTime, this.name, this.getCheckoutLink());
        },
        getAvailableModels() {
            var response = [];
            var type = 'M1';

            if (this.product.type.toLowerCase() == 'pkw') {
                type = 'M1';
            } else if (this.product.type.toLowerCase() == 'lkw') {
                type = 'N1';
            } else if (this.product.type.toLowerCase() == 'moto') {
                type = 'L';
            }

            this.models.forEach(model => {
                if (model.Make.toLowerCase() == this.product.vehicle.name.toLowerCase() && model.Type.toLowerCase() == type.toLowerCase()) {
                    response.push(model.Model);
                }
            });

            return response;
        },
        addLightBox() {
            var myDiv = document.createElement('div');
            myDiv.id = 'vue-product-lightbox';

            myDiv.innerHTML =
                "<div class='vue-product-lightbox__close lightbox__close'></div><div class='vue-product-lightbox__close lightbox__mask'></div><div class='lightbox__container'><img src='" +
                this.coc +
                "' alt='" +
                this.name +
                "'></div>";

            document.body.appendChild(myDiv);

            const lbox = document.querySelectorAll('.vue-product-lightbox__close');

            for (let i = 0; i < lbox.length; i++) {
                lbox[i].addEventListener('click', function() {
                    var lb = document.querySelector('#vue-product-lightbox');
                    lb.parentNode.removeChild(lb);
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
// h2 {
//     font-size: 20px !important;
//     line-height: 24px !important;
// }

.product__wrapper-brand {
    display: flex;
    position: relative;
    //min-height: 290px;

    @include less-than(md) {
        min-height: 288px;
    }
}

.blue-link {
    margin-left: 0.5rem;
    color: #1a0dab;
    cursor: pointer;
    font-weight: normal;
    @include transition();

    &:hover {
        text-decoration: underline;
    }
}

.product-brand {
    position: relative;
    display: flex;
    align-items: stretch;
    width: 100%;
    min-height: 100%;
    border: 1px solid #dedede;
    padding: 20px 20px 20px 0;
    background: $white;
    @include box-sizing(border-box);
    @include transition();

    @include less-than(md) {
        width: 100%;
        max-width: 100%;
        padding: 20px;
        display: block;
    }

    &.--active {
        z-index: 60;
        //position: absolute;
        @include shadow(0, 2px, 40px, 0, rgba(0, 0, 0, 0.2));
    }
}

.product__info-header {
    text-decoration: none;
}

.product__content-brand {
    display: flex;
    flex-direction: column;
    border-left: 1px solid #dedede;
    padding-left: 30px;
    flex: 1;

    @include less-than(md) {
        display: flex;
        border: 0;
        padding: 0;
    }

    @include less-than(sm) {
        width: 100%;
        @include box-sizing(border-box);
    }
}

.product__logo-wrapper-brand {
    max-width: 235px;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    @include box-sizing(border-box);

    @include less-than(md) {
        background-size: 60px auto;
        background-position: center right;
        margin: 0 auto;
        display: none;
    }

    &.--mobile {
        display: none;

        @include less-than(md) {
            margin-top: 30px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

.product__logo-brand {
    width: 100px;
    height: 100px;
    display: block;
    margin: auto;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: $white;
    z-index: 10;
    top: 50%;
    left: 50%;
    @include border-radius(100px);
    @include translate(-50%, -50px, 0);

    @include less-than(md) {
        width: 100px;
    }

    a {
        display: flex;
        align-items: center;
    }

    img {
        object-fit: contain !important;
        width: 100% !important;
        height: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
    }
}

.product__coc {
    width: 100%;
    max-height: 180px;
    position: relative;

    @include less-than(md) {
        height: 150px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.product__info-price-wrapper {
    display: flex;
    align-items: center;

    @include less-than(md) {
        padding-top: 10px;
        display: block;
        text-align: left;
    }
}

.product__info-price {
    font-weight: $fwBold;
    font-size: 34px;
    line-height: 40px;
    white-space: nowrap;
    margin-right: 10px;
    color: $black !important;

    &:first-child {
        margin-right: 5px;
    }

    @include less-than(md) {
        font-size: 30px;
        line-height: 30px;
    }

    &.--currency {
        @include less-than(sm) {
            margin-right: 10px;
        }
    }
}

.product__info-price-vat {
    display: flex;
    // flex-direction: column;
    margin-top: 15px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 14px;

    @include less-than(md) {
        display: block;
        margin-top: 5px;

        strong {
            display: inline-block;
            margin-left: 5px;
        }
    }

    @include less-than(sm) {
        display: inline-block;
    }

    strong,
    span {
        font-weight: $fwSemi;
    }
}

.product__info-processing-time {
    font-weight: $fwSemi;
    font-size: 14px;
    color: #4e4e4e;
    line-height: 19px;

    @include less-than(md) {
        text-align: left;
        margin-top: 5px;
        margin-bottom: 20px;
    }

    strong {
        font-weight: $fwSemi;

        @include less-than(md) {
            display: block;
        }

        @include less-than(sm) {
            display: inline-block;
        }
    }
}

.btn {
    display: inline-flex;
    width: auto;
    max-width: 300px;
    align-items: center;
    justify-content: center;
    height: 50px;
    background: $green;
    color: $white;
    font-weight: $fwSemi;
    font-size: 17px;
    text-decoration: none;
    @include shadow(0, 2px, 4px, 0, rgba(0, 0, 0, 0.2));
    @include border-radius(5px);
    @include transition();

    &:hover {
        background: $greenDark;
        color: $white;
        text-decoration: none;
    }

    @include less-than(sm) {
        display: flex;
        max-width: 100%;
    }
}

.product__note {
    margin-top: 15px;
    font-size: 14px;
    line-height: 19px;
    font-weight: $fwNormal;
    color: $black;
    hyphens: auto;

    a {
        color: $blue;
        text-decoration: none;
        font-size: 16px;
        display: block;
        margin-top: 10px;

        &:hover {
            text-decoration: underline;
        }
    }

    a.headline {
        display: block;
        font-weight: $fwBold;
        color: $black;
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 10px;
        text-decoration: none;

        &:hover {
            text-decoration: none;
            color: $black;
        }
    }
}

// h2 {
//     font-size: 25px !important;
//     line-height: 30px !important;
//     margin: 0 !important;

//     @include less-than(md) {
//         font-size: 20px !important;
//         line-height: 25px !important;
//     }
// }

h4 {
    margin: 5px 0 0 0;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: $fwSemi;
}

.note {
    font-weight: $fwSemi;
    font-size: 14px;
    color: #4e4e4e;
    letter-spacing: 0;
    margin: 20px 0 0px 0 !important;
    padding: 0;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.7);
}

.product-icon {
    min-width: 72px;
    width: 72px;
    height: 72px;
    display: block;
    border: 1px solid #dedede;
    margin-right: 10px;
    @include border-radius(50%);

    &.lkw {
        background: url($basePath+'/icons/lkw-orange.svg') no-repeat center;
        background-size: 70% auto;
    }

    &.pkw {
        background: url($basePath+'/icons/pkw-orange.svg') no-repeat center;
        background-size: 70% auto;
    }

    &.moto {
        background: url($basePath+'/icons/moto-orange.svg') no-repeat center;
        background-size: 70% auto;
    }
}

.d-flex {
    display: flex;

    @include less-than(md) {
        display: block;
        text-align: center;
    }
}

.align-items-center {
    align-items: center;
}

.product__info-what-need {
    font-weight: $fwSemi;
    font-size: 14px;
    text-decoration: underline;
    letter-spacing: 0;
    line-height: 19px;
    color: #204ea8;
    cursor: pointer;
    margin-top: 10px;
    @include transition();

    &:hover {
        text-decoration: none;
    }
}

.subhead {
    font-weight: $fwSemi;
    font-size: 14px;
    color: #4e4e4e;
    letter-spacing: 0;
    display: block;
    line-height: 19px;
    @include opacity(0.6);
}

.product__content-title {
    font-weight: $fwBold;
    font-size: 18px;
    color: #1d1d1d;
    line-height: 24px;
    letter-spacing: 0;
    text-decoration: none;

    @include less-than(md) {
        font-size: 16px;
        line-height: 22px;
    }
}

.flex-btn {
    flex: 1;
    max-width: 245px;
    margin-left: auto;

    @include less-than(md) {
        max-width: 100%;
    }
}

.flex-cont {
    margin-top: 20px;
    margin-bottom: 10px;
}

.product__coc-btn {
    font-weight: $fwSemi;
    font-size: 14px;
    text-decoration: underline;
    letter-spacing: 0;
    line-height: 19px;
    color: #204ea8;
    cursor: pointer;
    margin-top: 10px;
    @include transition();

    &:hover {
        text-decoration: none;
    }
}

.product__info-header {
    display: flex;
    align-items: center;
}

.btn-select-model {
    cursor: pointer;
}
</style>
