<template>
    <div class="tabs">
        <a
            class="tab"
            v-for="(tab, index) in productCategories"
            :id="string_to_slug(tab.name)"
            :key="index"
            :class="[
                currentProductCategory.value == tab.value || (index == 0 && !currentProductCategory.value) || tab.value == typePage ? '--active' : '',
                tab.value.toLowerCase(),
            ]"
            @click.prevent="changeTab(tab)"
            :href="getTabLink(tab)"
        >
            <div class="tab__icon"></div>
            <div class="tab__name">
                {{ tab.name }}
            </div>
        </a>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
    name: 'ProductCategories',
    data() {
        return {
            productCategories: [
                {
                    name: this.$t('categories.m1'),
                    value: 'PKW',
                },
                {
                    name: this.$t('categories.n1'),
                    value: 'LKW',
                },
                {
                    name: this.$t('categories.l'),
                    value: 'MOTO',
                },
            ],
        };
    },
    created() {
        if (!this.currentProductCategory.value) this.setProductCategory(this.productCategories[0]);
    },
    mounted() {
        if (window.location.hash) {
            if (!this.typePage) {
                if (window.location.hash.substring(1) == this.string_to_slug(this.$t('categories.m1'))) {
                    this.changeTab(this.productCategories[0]);
                }

                if (window.location.hash.substring(1) == this.string_to_slug(this.$t('categories.n1'))) {
                    this.changeTab(this.productCategories[1]);
                }

                if (window.location.hash.substring(1) == this.string_to_slug(this.$t('categories.l'))) {
                    this.changeTab(this.productCategories[2]);
                }
            }
        }
    },
    computed: {
        ...mapState(['currentProductCategory', 'productSlice', 'productSliceStep', 'showMoreBtn', 'typePage']),
    },
    methods: {
        ...mapMutations(['setProductCategory', 'setProductSlice', 'setShowMoreBtnVisibility', 'setFilterAlphabet', 'setFilterSearch']),
        getTabLink(tab) {
            if (tab.value.toLowerCase() == 'pkw') {
                return this.$t('typePageLinkPkw');
            } else if (tab.value.toLowerCase() == 'lkw') {
                return this.$t('typePageLinkLkw');
            } else if (tab.value.toLowerCase() == 'moto') {
                return this.$t('typePageLinkMoto');
            }
        },
        changeTab(tab) {
            if (this.typePage) {
                if (tab.value.toLowerCase() == 'pkw') {
                    window.open(this.$t('typePageLinkPkw'), '_self');
                } else if (tab.value.toLowerCase() == 'lkw') {
                    window.open(this.$t('typePageLinkLkw'), '_self');
                } else if (tab.value.toLowerCase() == 'moto') {
                    window.open(this.$t('typePageLinkMoto'), '_self');
                }
            } else {
                this.setProductCategory(tab);
                this.setProductSlice(this.productSliceStep);
                this.setFilterAlphabet('');
                this.setFilterSearch('');

                window.history.pushState({ name: tab.name }, null, '#' + this.string_to_slug(tab.name));
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 -10px 50px;
    position: relative;

    @include less-than(md) {
        margin: 0 -3px 25px;
    }

    &:after {
        content: '';
        width: 100vw;
        height: 1px;
        background: $border;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}

.tab {
    border: 1px solid $border;
    background: $tabBg;
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    height: 115px;
    align-items: center;
    justify-content: center;
    flex: 1;
    cursor: pointer;
    position: relative;
    z-index: 2;
    text-decoration: none !important;
    @include border-top-right-radius(5px);
    @include border-top-left-radius(5px);
    @include transition();

    &:hover {
        background: #f0f1f2;
    }

    &:after {
        content: '';
        width: 100%;
        height: 4px;
        background: $white;
        bottom: -2px;
        position: absolute;
        @include transition();
        @include opacity(0);
    }

    @include less-than(md) {
        margin: 0 3px;
        height: 90px;
    }

    &.--active {
        position: relative;
        background: $white;

        &:after {
            @include opacity(1);
        }
    }
}

.tab__icon {
    width: 86px;
    height: 36px;
    display: block;
    margin-bottom: 5px;
    background-repeat: no-repeat;
    background-position: center;
    //@include transition();

    @include less-than(md) {
        width: 43px;
        height: 18px;
        background-size: contain;
    }

    .tab.pkw.--active & {
        background-image: url($basePath+'icons/pkw-orange.svg');
        @include opacity(1);
    }

    .tab.lkw.--active & {
        background-image: url($basePath+'icons/lkw-orange.svg');
        @include opacity(1);
    }

    .tab.moto.--active & {
        background-image: url($basePath+'icons/moto-orange.svg');
        @include opacity(1);
    }

    .tab.pkw & {
        background-image: url($basePath+'icons/pkw.svg');
        @include opacity(0.5);
    }

    .tab.lkw & {
        background-image: url($basePath+'icons/lkw.svg');
        @include opacity(0.5);
    }

    .tab.moto & {
        background-image: url($basePath+'icons/moto.svg');
        @include opacity(0.5);
    }
}

.tab__name {
    font-size: 18px;
    line-height: 24px;
    font-weight: $fwBold;
    color: #7c7c7c;
    text-align: center;
    padding: 0 10px;
    word-break: break-word;
    @include transition();

    @include less-than(md) {
        font-size: 13px;
        line-height: 15px;
    }

    .tab.--active & {
        color: $orange;
    }
}
</style>
