// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/icons/close.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".products{display:flex;margin:-10px;flex-wrap:wrap}.products-brand{display:flex;flex-direction:column;align-items:center}.product__wrapper-brand{margin:0 0 30px 0;width:100%;max-width:850px}@media(max-width:1023.9px){.product__wrapper-brand{margin-bottom:10px}}.product__wrapper-brand:last-child{margin-bottom:0}.product__wrapper{margin:10px;width:calc(25% - 20px);max-width:calc(25% - 20px)}.app-container.--three-cols .product__wrapper{width:calc(33.33333% - 20px);max-width:calc(33.33333% - 20px)}@media(max-width:1023.9px){.app-container.--three-cols .product__wrapper,.product__wrapper{width:calc(50% - 20px);max-width:calc(50% - 20px)}}.btnn{margin:35px auto 0;display:flex;align-items:center;justify-content:center;width:250px;height:50px;background:#fff;border:1px solid #dedede;font-size:17px;color:#000;font-weight:600;cursor:pointer;box-shadow:0 2px 4px 0 rgba(0,0,0,.2);border-radius:5px;transition:all .6s cubic-bezier(.22,1,.36,1)}.btnn:hover{color:#f58220;text-decoration:none}.mt-3{margin-top:30px}.hub-spot-form{z-index:1000;display:flex;align-items:center;justify-content:center;overflow:auto}.hub-spot-form,.hubspot-form-overlay{position:fixed;top:0;left:0;width:100%;height:100%}.hubspot-form-overlay{z-index:1;background:rgba(0,0,0,.3)}.hubspot-container{background:#fff;padding:30px 30px 15px;width:360px;min-height:250px;border-radius:8px;z-index:2;position:relative;box-sizing:border-box}.hubspot-close{position:absolute;background:rgba(0,0,0,.4);-webkit-mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;width:50px;height:50px;top:0;right:0;cursor:pointer;transition:all .6s cubic-bezier(.22,1,.36,1)}.hubspot-close:hover{background:#000}.hubspot-wrapper{max-height:100%}.hubspot-wrapper__inner{padding:50px 0}", ""]);
// Exports
module.exports = exports;
