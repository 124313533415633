<template>
    <div class="filter">
        <div class="filter-alphabet">
            <span>{{ $t('filter') }}:</span>
            <span
                class="alpha"
                v-for="(alpha, index) in alphabets[currentProductCategory.value].sort()"
                :key="index"
                @click="setAlpha(alpha)"
                :class="{ '--active': filterAlphabet == alpha }"
            >
                {{ alpha }}
            </span>
        </div>

        <div class="filter-search">
            <input
                class="search-field"
                type="text"
                name="search"
                :value="filterSearch"
                @keyup="searchFilter($event)"
                :placeholder="$t('searchPlaceholder')"
                ref="search"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapMutations } from 'vuex';

export default {
    name: 'ProductFilter',
    components: {},
    data() {
        return {};
    },
    computed: {
        ...mapState(['filterAlphabet', 'filterSearch', 'productSliceStep', 'currentProductCategory', 'alphabets']),

        // filterSearch: {
        //     get() {
        //         return this.$store.state.filterSearch
        //     },
        //     set(value) {
        //         this.$store.commit("setFilterSearch", value)
        //     }
        // }
    },
    methods: {
        ...mapMutations(['setFilterAlphabet', 'setFilterSearch', 'setProductSlice']),
        setAlpha(alpha) {
            if (alpha == this.filterAlphabet) {
                this.setFilterAlphabet('');
            } else {
                this.setFilterAlphabet(alpha);
            }

            this.setProductSlice(this.productSliceStep);

            window.history.pushState(
                { name: alpha },
                null,
                '#' + this.string_to_slug(this.currentProductCategory.name) + '-' + this.string_to_slug(alpha)
            );
        },
        searchFilter(event) {
            this.$store.commit('setFilterSearch', event.target.value);

            if (event.keyCode == '13') {
                this.$refs.search.blur();
            }
        },
        // setSearch(event) {
        //     this.setFilterSearch(event.target.value)
        // }
    },
};
</script>

<style lang="scss" scoped>
.filter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 35px;

    @include less-than(md) {
        margin-bottom: 30px;
    }
}

.filter-search {
    width: 100%;
    display: none;
    position: relative;

    @include less-than(md) {
        display: block;
    }

    &:after {
        content: '';
        width: 19px;
        height: 21px;
        position: absolute;
        right: 10px;
        background: url($basePath+'icons/loupe.svg') no-repeat center;
        z-index: 10;
        top: 50%;
        margin-top: -10px;
    }
}

.search-field {
    background: #ffffff !important;
    border: 1px solid #dedede !important;
    width: 100%;
    height: 45px;
    padding: 0 35px 0 10px;
    color: $black;
    font-size: 16px;
    @include border-radius(5px);
    @include box-sizing(border-box);

    @include placeholder() {
        color: $black;
        @include opacity(1);
    }

    &:focus {
        outline: none;
    }
}

.filter-alphabet {
    display: flex;

    @include less-than(md) {
        display: none;
    }

    span {
        margin: 0 5px;
        font-size: 20px;
        margin-right: 20px;

        &.alpha {
            margin: -0.1rem 0 0 0;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            text-decoration: none;
            text-transform: uppercase;
            color: $blue;
            border: 1px solid $white;
            @include border-radius(2px);
            @include transition();
            @include box-sizing(border-box);

            &:hover {
                border: 1px solid $blue;
            }

            &.--active {
                background: #e8e6f6;
            }
        }
    }
}
</style>
