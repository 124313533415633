<template>
    <div class="app-container" ref="appContainer">
        <product-categories v-show="!brandPage && !detailPage"></product-categories>

        <product-filter v-show="!loading && !brandPage && !detailPage"></product-filter>

        <spinner :visible="loading"></spinner>

        <div class="products" v-if="!brandPage && !detailPage">
            <product
                v-for="(product, index) in filteredProducts.slice(0, productSlice)"
                :key="index"
                :price="product.priceRaw"
                :priceWithVat="product.priceVat"
                :processingTime="product.deliveryTime"
                :logo="product.logo"
                :note="product.note"
                :name="product.vehicle.name"
                :product-id="product.id"
                :product="product"
                @unavailable="onUnavailable($event)"
            ></product>
        </div>

        <div class="products-brand" :id="`brand-${brandPage}`" v-if="brandPage || detailPage">
            <product-brand
                v-for="(product, index) in brandProducts"
                :key="index"
                :price="product.priceRaw"
                :priceWithVat="product.priceVat"
                :processingTime="product.deliveryTime"
                :logo="product.logo"
                :note="product.note"
                :name="product.vehicle.name"
                :product-id="product.id"
                :coc="product.cocScan"
                :product="product"
            ></product-brand>
        </div>

        <product-filter v-show="!loading && !brandPage && !detailPage" class="mt-3"></product-filter>

        <div class="btnn --primary" @click="showMore()" v-show="filteredProducts.length > productSlice && !brandPage && !detailPage">
            {{ $t('showMore') }}
        </div>

        <div class="hub-spot-form" v-show="showHubSpotForm">
            <div class="hubspot-form-overlay" @click="closeHubSpotForm()"></div>
            <div class="hubspot-wrapper">
                <div class="hubspot-wrapper__inner">
                    <div class="hubspot-container">
                        <div class="hubspot-close" @click="closeHubSpotForm()"></div>
                        <div id="hubSpotForm"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Spinner from './components/Spinner.vue';
import Product from './components/Product.vue';
import ProductBrand from './components/ProductBrand.vue';
import ProductFilter from './components/ProductFilter.vue';
import ProductCategories from './components/ProductCategories.vue';
import { mapState, mapMutations } from 'vuex';
import axios from 'axios';

//axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*"
axios.defaults.headers.common['Content-Type'] = 'application/json';

export default {
    name: 'App',
    components: {
        Spinner,
        Product,
        ProductBrand,
        ProductFilter,
        ProductCategories,
    },
    data() {
        return {
            loading: false,
            uid: null,
            partner: null,
            showHubSpotForm: false,
        };
    },
    mounted() {
        //console.log(process.env.VUE_APP_BASEE_URL)
        document.addEventListener('keydown', event => {
            if (event.key === 'Escape') {
                var lb = document.querySelector('#vue-delivery-lightbox, #vue-proceed-lightbox');

                if (lb) {
                    lb.classList.remove('--visible');

                    setTimeout(() => {
                        lb.parentNode.removeChild(lb);
                    }, 200);
                }
            }
        });

        const appContainer = this.$refs.appContainer;

        if (appContainer.offsetWidth < 700) {
            this.$refs.appContainer.classList.add('--three-cols');
        } else {
            this.$refs.appContainer.classList.remove('--three-cols');
        }

        this.initHubSpotForm();
    },
    created() {
        if (window.sessionStorage.getItem('vuex-checkout') != null) {
            if (JSON.parse(window.sessionStorage.getItem('vuex-checkout')).orderId != 0) {
                window.sessionStorage.removeItem('vuex-checkout');
            }
        }
        //window.sessionStorage.clear()

        this.loading = true;

        if (window.userId) {
            this.uid = window.userId;
        }

        if (window.productBrand && !window.vehicleType) {
            this.setBrandPage(window.productBrand);
        }

        if (window.vehicleType && !window.productBrand) {
            this.setProductCategory({ value: window.vehicleType });
            this.setTypePage(window.vehicleType);
        }

        if (window.vehicleType && window.productBrand) {
            this.setDetailPage({ type: window.vehicleType, brand: window.productBrand });
        }

        //get partner
        this.getPartner();

        this.fetchData();
    },
    computed: {
        ...mapState([
            'apiUrl',
            'apiProductList',
            'alphabets',
            'currentProductCategory',
            'products',
            'productSlice',
            'productSliceStep',
            'filterAlphabet',
            'filterSearch',
            'productTypes',
            'brandPage',
            'typePage',
            'detailPage',
            'productsRaw',
        ]),
        filteredProducts() {
            let category = !this.currentProductCategory.value ? this.productTypes[0] : this.currentProductCategory.value;

            return this.products[category].filter(product => {
                if (this.filterAlphabet != '') {
                    if (product.vehicle.name.charAt(0).toLowerCase() == this.filterAlphabet) {
                        return product;
                    }
                } else if (this.filterSearch != '') {
                    if (product.vehicle.name.toLowerCase().includes(this.filterSearch.toLowerCase())) {
                        return product;
                    }
                } else {
                    return product;
                }
            });
        },
        brandProducts() {
            if (this.detailPage) {
                return this.productsRaw.filter(product => {
                    if (
                        product.vehicle.name.toLowerCase() == this.detailPage.brand.toLowerCase() &&
                        product.type.toLowerCase() == this.detailPage.type.toLowerCase()
                    ) {
                        return product;
                    }
                });
            } else {
                return this.productsRaw.filter(product => {
                    if (product.vehicle.name.toLowerCase() == this.brandPage.toLowerCase()) {
                        return product;
                    }
                });
            }
        },
    },
    methods: {
        ...mapMutations([
            'setProducts',
            'setProductSlice',
            'setAlphabets',
            'setBrandPage',
            'setTypePage',
            'setDetailPage',
            'setProductsRaw',
            'setProductCategory',
        ]),
        initHubSpotForm() {
            const script = document.createElement('script');
            script.src = '//js.hsforms.net/forms/v2.js';
            script.type = 'text/javascript';
            document.body.appendChild(script);

            // script.addEventListener('load', () => {
            //     if (window.hbspt) {
            //         window.hbspt.forms.create({
            //             region: 'na1',
            //             portalId: '8304351',
            //             formId: 'c7b1a0ce-a323-41e8-8213-e5cd56a221d6',
            //             target: '#hubSpotForm',
            //         });
            //     }
            // });

            // window.addEventListener('message', event => {
            //     if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmit') {
            //         let hiddenField = document
            //             .querySelector('iframe.hs-form-iframe')
            //             .contentWindow.document.querySelector('input[name="individual_request__product"]');
            //         hiddenField.value = 'moja_hodnota';
            //     }
            // });
        },
        onUnavailable(data) {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    region: 'na1',
                    portalId: '8304351',
                    formId: this.getHubSpotId(),
                    target: '#hubSpotForm',
                });
            }

            this.showHubSpotForm = true;

            setTimeout(() => {
                let hiddenField = document
                    .querySelector('iframe.hs-form-iframe')
                    .contentWindow.document.querySelector('input[name="individual_request__product"]');
                hiddenField.value = data;
            }, 1000);
        },
        getHubSpotId() {
            if (this.$i18n.locale == 'de') {
                return '04b1fcfc-00e3-491a-9f8d-93f96c8661b5';
            } else if (this.$i18n.locale == 'it') {
                return '0325de1f-6990-4451-8ea4-0147cef0ef5f';
            } else if (this.$i18n.locale == 'es') {
                return '281799f4-9993-4a9b-9205-52a2b4f11a0e';
            } else if (this.$i18n.locale == 'fr') {
                return '4ef35a37-653f-4299-95e1-0500e072d6b2';
            } else {
                return 'c7b1a0ce-a323-41e8-8213-e5cd56a221d6';
            }
        },
        closeHubSpotForm() {
            this.showHubSpotForm = false;
            document.getElementById('hubSpotForm').innerHTML = '';
        },
        fetchData() {
            var dataFetchUrl = this.apiUrl + this.apiProductList + this.$i18n.locale + (this.uid ? '?uid=' + this.uid : '');

            if (this.typePage) {
                dataFetchUrl = this.apiUrl + this.apiProductList + this.$i18n.locale + `?conditions[coc_vozidla.TYP][0]=${this.typePage}`;
            }

            axios
                .get(dataFetchUrl)
                .then(response => {
                    Array.prototype.move = function(from, to) {
                        this.splice(to, 0, this.splice(from, 1)[0]);
                    };

                    let pkw = this.productTypes[0];
                    let data = response.data;
                    let mostPopular = ['VW', 'TOYOTA', 'SEAT', 'OPEL', 'MERCEDES BENZ', 'KIA', 'FORD', 'FIAT', 'BMW', 'AUDI'];

                    //ulozim raw data
                    this.setProductsRaw(data);

                    //zoradim PKW podla most popular
                    for (let i = 0; i < data.length; i++) {
                        if (mostPopular.includes(data[i].vehicle.name) && data[i].type == pkw) {
                            data.move(i, 0);
                        }
                    }

                    //nacitam produkty podla typu do pola
                    for (let i = 0; i < response.data.length; i++) {
                        this.setProducts(response.data[i]);
                    }

                    //vytvorim abecedu pre kazdy typ vozidla
                    for (let e = 0; e < this.productTypes.length; e++) {
                        let type = this.productTypes[e];

                        for (let i = 0; i < this.products[type].length; i++) {
                            let letter = this.products[type][i].vehicle.name.charAt(0).toLowerCase();

                            if (!this.alphabets[type].includes(letter)) {
                                this.setAlphabets({
                                    key: type,
                                    value: letter,
                                });
                            }
                        }
                    }

                    this.loading = false;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        showMore() {
            if (this.productSlice < this.filteredProducts.length) {
                this.setProductSlice(this.productSlice + this.productSliceStep);

                if (this.productSlice >= this.filteredProducts.length) {
                    this.setProductSlice(this.filteredProducts.length);
                }
            }
        },
        getPartner() {
            const params = new URLSearchParams(window.location.search);

            if (params.has('partner')) {
                this.partner = params.get('partner');
                this.setCookie('cocPartner', this.partner, 7);
            }
        },
    },
};
</script>

<style lang="scss">
$productMargin: 20px;

.products {
    display: flex;
    margin: -($productMargin / 2);
    flex-wrap: wrap;
}

.products-brand {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.product__wrapper-brand {
    margin: 0 0 30px 0;
    width: 100%;
    max-width: 850px;

    @include less-than(md) {
        margin-bottom: 10px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.product__wrapper {
    margin: 10px;
    width: calc(100% / 4 - #{$productMargin});
    max-width: calc(100% / 4 - #{$productMargin});

    .app-container.--three-cols & {
        width: calc(100% / 3 - #{$productMargin});
        max-width: calc(100% / 3 - #{$productMargin});

        @include less-than(md) {
            width: calc(100% / 2 - #{$productMargin});
            max-width: calc(100% / 2 - #{$productMargin});
        }
    }

    @include less-than(md) {
        width: calc(100% / 2 - #{$productMargin});
        max-width: calc(100% / 2 - #{$productMargin});
    }
}

.btnn {
    margin: 35px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 50px;
    background: $white;
    border: 1px solid #dedede;
    font-size: 17px;
    color: $black;
    font-weight: $fwSemi;
    cursor: pointer;
    @include shadow(0, 2px, 4px, 0, rgba(0, 0, 0, 0.2));
    @include border-radius(5px);
    @include transition();

    &:hover {
        color: $orange;
        text-decoration: none;
    }
}

.mt-3 {
    margin-top: 30px;
}

.hub-spot-form {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
}

.hubspot-form-overlay {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
}

.hubspot-container {
    background: $white;
    padding: 30px 30px 15px;
    width: 360px;
    min-height: 250px;
    border-radius: 8px;
    z-index: 2;
    position: relative;
    box-sizing: border-box;
}

.hubspot-close {
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    mask: url($basePath+'icons/close.svg') no-repeat center;
    width: 50px;
    height: 50px;
    top: 0;
    right: 0;
    cursor: pointer;
    @include transition();

    &:hover {
        background: $black;
    }
}

#hubSpotForm {
}

.hubspot-wrapper {
    max-height: 100%;
}

.hubspot-wrapper__inner {
    padding: 50px 0;
}
</style>
