const globalVueMixins = {
    methods: {
        // asset($path) {
        //  if (process.env.NODE_APP === "production")
        //      return process.env.VUE_APP_ASSET_URL + $path
        //  else {
        //      return $path
        //  }
        // },
        getCookie(cname) {
            var name = cname + '=';
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i].trim();
                if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
            }
            return '';
        },
        setCookie(cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
            var expires = 'expires=' + d.toUTCString();
            document.cookie = cname + '=' + cvalue + ';path=/;' + expires;

            return this;
        },
        string_to_slug(str) {
            str = str.replace(/^\s+|\s+$/g, ''); // trim
            str = str.toLowerCase();

            // remove accents, swap ñ for n, etc
            var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
            var to = 'aaaaeeeeiiiioooouuuunc------';
            for (var i = 0, l = from.length; i < l; i++) {
                str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
            }

            str = str
                .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
                .replace(/\s+/g, '-') // collapse whitespace and replace by -
                .replace(/-+/g, '-'); // collapse dashes

            return str;
        },
        addBusinessDays(date, daysToAdd) {
            var cnt = 0;
            var tmpDate = this.$moment(date).locale('en');

            if (this.checkIsAfterTwoClock()) {
                daysToAdd = parseInt(daysToAdd) + 1;
            }

            while (cnt < daysToAdd) {
                tmpDate = tmpDate.add(1, 'days');
                if (
                    tmpDate.weekday() !=
                        this.$moment()
                            .locale('en')
                            .day('Sunday')
                            .weekday() &&
                    tmpDate.weekday() !=
                        this.$moment()
                            .locale('en')
                            .day('Saturday')
                            .weekday()
                ) {
                    cnt = cnt + 1;
                }
            }
            return tmpDate.format('DD.MM.YYYY');
        },
        isInDocumentField(product, type) {
            let isInArray = product.requiredOrderAttributes.find(o => o.name === type);

            return isInArray;
        },
        showPopup(product, name) {
            var myDiv = document.createElement('div');
            myDiv.id = 'vue-proceed-lightbox';

            // prettier-ignore
            var noteInfo = product.note ? `<p class="note"><strong>${this.$t("popup.pleaseNote")}</strong>${product.note}</p>` : '';

            // prettier-ignore
            myDiv.innerHTML = `
                <div class='vue-proceed-lightbox__close lightbox-proceed__mask'></div>
                    <div class="lightbox-inner">
                        <div class="lightbox-inner__wrapper">
                            <div class='lightbox-proceed__container'>
                                <div class='vue-proceed-lightbox__close lightbox-proceed__close btn-close-modal-get-coc'></div>
                                <div class='lightbox-proceed__container-inner'>
                                    <h3>
                                        ${this.$t("popup.title", { product: name })}
                                    </h3>
                                    <p>
                                        ${this.$t("popup.text", { product: name })}
                                    </p>

                                    <ul>
                                        <li>
                                            ${this.$t("vehicle.vin")} <span class="vue-tooltip-info" tip="${this.$t("tooltips.docVinNumber")}"></span>
                                        </li>
                                        ${this.isInDocumentField(product, 'ceh') ? '<li>'+this.$t("vehicle.typeApprovalNo")+`<span class="vue-tooltip-info" tip="${this.$t("tooltips.typeApprovalNumber")}"></span></li>` : ''}
                                        ${this.isInDocumentField(product, 'fv') ? '<li>'+this.$t("vehicle.color")+`<span class="vue-tooltip-info" tip="${this.$t("tooltips.vehicleColor")}"></span></li>` : ''}
                                        ${this.isInDocumentField(product, 'pm') ? '<li>'+this.$t("vehicle.numberOfSeats")+`<span class="vue-tooltip-info" tip="${this.$t("tooltips.numberOfSeats")}"></span></li>` : ''}
                                        ${this.isInDocumentField(product, 'cm') ? '<li>'+this.$t("vehicle.engineNumber")+'</li>' : ''}
                                        ${this.isInDocumentField(product, 'tp') ? '<li>'+this.$t("vehicle.typeOfGearbox")+'</li>' : ''}
                                        ${this.isInDocumentField(product, 'fcy') ? '<li>'+this.$t("vehicle.firstRegistrationCountry")+'</li>' : ''}
                                        ${this.isInDocumentField(product, 'ncy') ? '<li>'+this.$t("vehicle.countryOfNextRegistration")+`<span class="vue-tooltip-info" tip="${this.$t("tooltips.countryNextRegistration")}"></span></li>` : ''}
                                        ${this.isInDocumentField(product, 'ktp') ? '<li>'+this.$t("vehicle.docs.cor")+`<span class="vue-tooltip-info" tip="${this.$t("tooltips.docCor")}"></span></li>` : ''}
                                        ${this.isInDocumentField(product, 'fhs') ? '<li>'+this.$t("vehicle.docs.identificationPlate")+`<span class="vue-tooltip-info" tip="${this.$t("tooltips.docIdentificationPlate")}"></span></li>` : ''}
                                        ${this.isInDocumentField(product, 'kz') ? '<li>'+this.$t("vehicle.docs.contract")+`<span class="vue-tooltip-info" tip="${this.$t("tooltips.docContract")}"></span></li>` : ''}
                                        ${this.isInDocumentField(product, 'op') ? '<li>'+this.$t("vehicle.docs.passport")+`<span class="vue-tooltip-info" tip="${this.$t("tooltips.docPassport")}"></span></li>` : ''}
                                        ${this.isInDocumentField(product, 'fst') ? '<li>'+this.$t("vehicle.docs.speedometer")+'</li>' : ''}
                                        ${this.isInDocumentField(product, 'vzh') ? '<li>'+this.$t("vehicle.docs.docConformite")+`<span class="vue-tooltip-info" tip="${this.$t("tooltips.docConformite")}"></span></li>` : ''}
                                        ${this.isInDocumentField(product, 'piaggio') ? '<li>'+this.$t("vehicle.docs.autocertification")+`<span class="vue-tooltip-info" tip='${this.$t("tooltips.docPiaggio")}'></span></li>` : ''}
                                        ${this.isInDocumentField(product, 'vinf') ? '<li>'+this.$t("vehicle.docs.docVinNumber")+`<span class="vue-tooltip-info" tip="${this.$t("tooltips.docVinNumber")}"></span></li>` : ''}
                                        ${this.isInDocumentField(product, 'mandat_fca') ? '<li>'+this.$t("vehicle.docs.mandat_fca")+`<span class="vue-tooltip-info" tip='${this.$t("tooltips.docMandatFca")}'></span></li>` : ''}
                                    </ul>

                                    ${noteInfo}

                                    <a href="${this.getCheckoutLink()}" class="btn btn-proceed">
                                        ${this.$t("popup.proceed")}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
            `

            document.body.appendChild(myDiv);

            setTimeout(() => {
                myDiv.classList.add('--visible');
            }, 50);

            const lbox = document.querySelectorAll('.vue-proceed-lightbox__close');

            for (let i = 0; i < lbox.length; i++) {
                lbox[i].addEventListener('click', function() {
                    var lb = document.querySelector('#vue-proceed-lightbox');
                    lb.classList.remove('--visible');

                    setTimeout(() => {
                        lb.parentNode.removeChild(lb);
                    }, 200);
                });
            }

            //tooltip
            Array.from(document.querySelectorAll('[tip]')).forEach(el => {
                let tip = document.createElement('div');

                tip.classList.add('tooltip');
                tip.innerHTML = el.getAttribute('tip');

                el.appendChild(tip);
            });
        },
        checkIsAfterTwoClock() {
            var now = this.$moment();
            var hourToCheck = 14;
            var dateToCheck = now.hour(hourToCheck);

            return this.$moment().isAfter(dateToCheck);
        },
        showDeliveryInfo(processingTime, productName, checkoutLink) {
            var myDiv = document.createElement('div');
            myDiv.id = 'vue-delivery-lightbox';

            // prettier-ignore
            myDiv.innerHTML = `
                <div class='vue-proceed-lightbox__close lightbox-proceed__mask'></div>
                    <div class="lightbox-inner">
                        <div class="lightbox-inner__wrapper">
                            <div class='lightbox-proceed__container'>
                                <div class='vue-proceed-lightbox__close lightbox-proceed__close btn-close-modal-delivery'></div>
                                <div class='lightbox-proceed__container-inner'>
                                    <h3 class="mb-48">
                                        ${this.$t("deliveryPopup.headline")}<br>
                                        ${this.$t("deliveryPopup.subheadline")}
                                    </h3>

                                    <div class="lightbox-delivery__timeline">
                                        <div class="lightbox-delivery__timeline-item">
                                            <h4 class="lightbox-delivery__timeline-title">
                                                <span>${this.$t("deliveryPopup.paymentReceived")}</span>
                                                <div class="lightbox-delivery__timeline-badge">
                                                    ${this.$moment().format("DD.MM.YYYY")}
                                                </div>
                                            </h4>
                                            <div class="lightbox-delivery__timeline-gray-box">
                                                ${this.$t("deliveryPopup.paymentInfo")}
                                            </div>
                                        </div>

                                        <div class="lightbox-delivery__timeline-item">
                                            <h4 class="lightbox-delivery__timeline-title">
                                                <span>${this.$t("deliveryPopup.orderProcessing")}</span>
                                                <div class="lightbox-delivery__timeline-badge --bordered">
                                                    ${this.$t("deliveryPopup.estimatedTime", {processingTime: processingTime})}
                                                </div>
                                            </h4>
                                            <div class="lightbox-delivery__timeline-gray-box">
                                                ${this.$t("deliveryPopup.orderInfo")}
                                            </div>
                                        </div>

                                        <div class="lightbox-delivery__timeline-item">
                                            <h4 class="lightbox-delivery__timeline-title">
                                                <span>${this.$t("deliveryPopup.delivery")}</span>
                                                <div class="lightbox-delivery__timeline-badge">
                                                    ${this.$t("deliveryPopup.deliveryBetween", {from: this.addBusinessDays(this.$moment(), processingTime), to: this.addBusinessDays(this.$moment(), parseInt(processingTime) + 7)})}
                                                </div>
                                            </h4>
                                            <div class="lightbox-delivery__timeline-gray-box">
                                                ${this.$t("deliveryPopup.deliveryInfo")}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="lightbox-delivery__boxes">
                                        <div class="lightbox-delivery__box">
                                            <div class="lightbox-delivery__box-header">
                                                <strong>${this.$t("deliveryPopup.digitalFast")}</strong>
                                                <span>${this.$t("deliveryPopup.digitalFastDelivery")}</span>
                                            </div>
                                            <div class="lightbox-delivery__box-body">
                                                <div class="lightbox-delivery__box-body-item">
                                                    <div class="lightbox-delivery__box-body-item-icon --email"></div>
                                                    <div class="lightbox-delivery__box-body-item-content">
                                                        <strong>${this.$t("deliveryPopup.digitalCopyByEmail")}</strong>
                                                        <div class="lightbox-delivery__box-body-item-desc">
                                                            ${this.$t("deliveryPopup.digitalCopyByEmailDesc")}
                                                        </div>
                                                        <span>${this.$t("deliveryPopup.estimatedDeliveryBetween")}</span>
                                                        <div>
                                                            <div class="lightbox-delivery__timeline-badge">
                                                                ${this.addBusinessDays(this.$moment(), parseInt(processingTime))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="lightbox-delivery__box-body-item">
                                                    <div class="lightbox-delivery__box-body-item-icon --ups"></div>
                                                    <div class="lightbox-delivery__box-body-item-content">
                                                        <strong>${this.$t("deliveryPopup.physicalCOCUPS")}</strong>
                                                        <span>${this.$t("deliveryPopup.estimatedDeliveryBetween")}</span>
                                                        <div>
                                                            <div class="lightbox-delivery__timeline-badge">
                                                                ${this.addBusinessDays(this.$moment(), parseInt(processingTime) + 1)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="lightbox-delivery__box">
                                            <div class="lightbox-delivery__box-header">
                                                <strong>${this.$t("deliveryPopup.fast")}</strong>
                                                <span>${this.$t("deliveryPopup.fastDelivery")}</span>
                                            </div>
                                            <div class="lightbox-delivery__box-body">
                                                <div class="lightbox-delivery__box-body-item">
                                                    <div class="lightbox-delivery__box-body-item-icon"></div>
                                                    <div class="lightbox-delivery__box-body-item-content">
                                                        <span>${this.$t("deliveryPopup.noDigitalCopy")}</span>
                                                    </div>
                                                </div>

                                                <div class="lightbox-delivery__box-body-item">
                                                    <div class="lightbox-delivery__box-body-item-icon --ups"></div>
                                                    <div class="lightbox-delivery__box-body-item-content">
                                                        <strong>${this.$t("deliveryPopup.physicalCOCUPS")}</strong>
                                                        <span>${this.$t("deliveryPopup.estimatedDeliveryBetween")}</span>
                                                        <div>
                                                            <div class="lightbox-delivery__timeline-badge">
                                                                ${this.addBusinessDays(this.$moment(), parseInt(processingTime) + 1)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="lightbox-delivery__box">
                                            <div class="lightbox-delivery__box-header">
                                                <strong>${this.$t("deliveryPopup.economy")}</strong>
                                                <span>${this.$t("deliveryPopup.economyDelivery")}</span>
                                            </div>
                                            <div class="lightbox-delivery__box-body">
                                                <div class="lightbox-delivery__box-body-item">
                                                    <div class="lightbox-delivery__box-body-item-icon"></div>
                                                    <div class="lightbox-delivery__box-body-item-content">
                                                        <span>${this.$t("deliveryPopup.noDigitalCopy")}</span>
                                                    </div>
                                                </div>

                                                <div class="lightbox-delivery__box-body-item">
                                                    <div class="lightbox-delivery__box-body-item-icon --physical"></div>
                                                    <div class="lightbox-delivery__box-body-item-content">
                                                        <strong>${this.$t("deliveryPopup.physicalCOC")}</strong>
                                                        <span>${this.$t("deliveryPopup.estimatedDeliveryBetween")}</span>
                                                        <div>
                                                            <div class="lightbox-delivery__timeline-badge">
                                                                ${this.addBusinessDays(this.$moment(), parseInt(processingTime) + 3)} - ${this.addBusinessDays(this.$moment(), parseInt(processingTime) + 7)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <a href="${checkoutLink}" class="btn --primary btn-delivery-proceed">
                                            ${this.$t("deliveryPopup.proceedToOrderBtn", { product: productName })}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            `

            document.body.appendChild(myDiv);

            setTimeout(() => {
                myDiv.classList.add('--visible');
            }, 50);

            const lbox = document.querySelectorAll('.vue-proceed-lightbox__close');

            for (let i = 0; i < lbox.length; i++) {
                lbox[i].addEventListener('click', function() {
                    var lb = document.querySelector('#vue-delivery-lightbox');
                    lb.classList.remove('--visible');

                    setTimeout(() => {
                        lb.parentNode.removeChild(lb);
                    }, 200);
                });
            }
        },
        getHumanProductType(type) {
            const lowerType = type.toLowerCase();

            if (lowerType == 'pkw') {
                return this.$t('schema.pkw');
            } else if (lowerType == 'lkw') {
                return this.$t('schema.lkw');
            } else if (lowerType == 'moto') {
                return this.$t('schema.moto');
            }
        },
        showSelectModel(product, models, checkoutLink) {
            var myDiv = document.createElement('div');
            myDiv.id = 'vue-model-lightbox';
            var type = 'M1';

            models.push('Other');

            if (product.type.toLowerCase() == 'pkw') {
                type = 'M1';
            } else if (product.type.toLowerCase() == 'lkw') {
                type = 'N1';
            } else if (product.type.toLowerCase() == 'moto') {
                type = 'L';
            }

            // prettier-ignore
            myDiv.innerHTML = `
                <div class='vue-proceed-lightbox__close lightbox-proceed__mask'></div>
                    <div class="lightbox-inner">
                        <div class="lightbox-inner__wrapper">
                            <div class='lightbox-proceed__container'>
                                <div class='vue-proceed-lightbox__close lightbox-proceed__close'></div>
                                <div class='lightbox-proceed__container-inner'>
                                    <h3 class="lightbox__model-title mb-48">
                                        <span class="lightbox__model-image">
                                            <img src="${product.logo}" />
                                        </span>
                                        <span>${this.$t("selectModelPopup.headline", { product: product.vehicle.name, type: type })}</span>
                                    </h3>

                                    <div class="lightbox__model-select">
                                        <strong>
                                            ${this.$t("selectModelPopup.choose")}
                                        </strong>
                                        <div class="autocomplete-input-wrapper">
                                            <input
                                                class="vue-autocomplete-input"
                                                type="text"
                                                placeholder="${this.$t('searchPlaceholder')}"
                                            />
                                            <input
                                                class="vue-autocomplete-input-hidden"
                                                type="hidden"
                                            />
                                        </div>
                                        <div class="autocomplete-results" id="vue-autocomplete-results"></div>
                                        <div class="autocomplete-model-info" id="vue-model-info" style="display: none;"></div>
                                    </div>

                                    <div>
                                        <a href="${checkoutLink}" class="btn --primary btn-select-model btn-select-model-modal" id="goto-checkout">
                                            ${this.$t("deliveryPopup.proceedToOrderBtn", { product: product.vehicle.name })}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            `;

            document.body.appendChild(myDiv);

            setTimeout(() => {
                myDiv.classList.add('--visible');
            }, 50);

            const lbox = document.querySelectorAll('.vue-proceed-lightbox__close');

            for (let i = 0; i < lbox.length; i++) {
                lbox[i].addEventListener('click', function() {
                    var lb = document.querySelector('#vue-model-lightbox');
                    lb.classList.remove('--visible');

                    setTimeout(() => {
                        lb.parentNode.removeChild(lb);
                    }, 200);
                });
            }

            // variables
            var input = document.querySelector('.vue-autocomplete-input');
            var hiddenInput = document.querySelector('.vue-autocomplete-input-hidden');
            var autocomplete_results = document.getElementById('vue-autocomplete-results');
            var that = this;

            // events
            input.onkeyup = function() {
                var input_val = this.value;

                var results = [];

                autocomplete_results.innerHTML = '';
                results = that.autocomplete(input_val, models);

                for (var i = 0; i < results.length; i++) {
                    autocomplete_results.innerHTML +=
                        '<span data-name="' + results[i] + '" class="vue-autocomplete-results__item">' + results[i] + '</span>';
                }
                autocomplete_results.style.display = 'block';
            };

            input.dispatchEvent(new KeyboardEvent('keyup'));

            document.addEventListener(
                'click',
                function(event) {
                    // If the clicked element doesn't have the right selector, bail
                    if (event.target.matches('.vue-autocomplete-results__item')) {
                        var el = event.target,
                            elems = document.querySelectorAll('.vue-autocomplete-results__item');

                        for (var i = 0; i < elems.length; i++) {
                            elems[i].classList.remove('active');
                        }

                        el.classList.add('active');
                        input.value = el.dataset.name;
                        hiddenInput.value = el.dataset.name;
                        autocomplete_results.style.display = 'none';
                        that.showResult(product, type, el.dataset.name);
                        document.getElementById('goto-checkout').href = checkoutLink + '&model=' + el.dataset.name;
                    }
                },
                false
            );
        },
        autocomplete(val, models) {
            if (val == '') {
                return models;
            }

            return models.filter(model => {
                if (val.length > 0) {
                    if (model.toLowerCase().includes(val.toLowerCase())) {
                        return model;
                    }
                }
            });
        },
        showResult(product, type, model) {
            var modelEl = document.getElementById('vue-model-info');

            modelEl.innerHTML = `
                <h3 class="model-info-title">${product.vehicle.name} ${model} (${type})</h3>
                <h4 class="model-info-subtitle">${product.priceRaw} € <span>(${this.$t('product.withVat')}: ${product.priceVat} €)</span></h4>
            `;

            if (product.note) {
                modelEl.innerHTML += `
                    <div class="model-info-box">
                        <strong>${this.$t('selectModelPopup.note')}</strong>
                        <p>${product.note}</p>
                    </div>
                `;
            }

            modelEl.innerHTML += `
                <div class="model-info-steps">
                    <strong>${this.$t('selectModelPopup.stepsToFinishHeadline')}</strong>
                    ${this.$t('selectModelPopup.stepsToFinish')}
                </div>
            `;

            modelEl.style.display = 'block';
        },
    },
};

export default globalVueMixins;
