// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/icons/loupe.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".filter[data-v-5db197e2]{display:flex;align-items:center;justify-content:flex-start;margin-bottom:35px}@media(max-width:1023.9px){.filter[data-v-5db197e2]{margin-bottom:30px}}.filter-search[data-v-5db197e2]{width:100%;display:none;position:relative}@media(max-width:1023.9px){.filter-search[data-v-5db197e2]{display:block}}.filter-search[data-v-5db197e2]:after{content:\"\";width:19px;height:21px;position:absolute;right:10px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;z-index:10;top:50%;margin-top:-10px}.search-field[data-v-5db197e2]{background:#fff!important;border:1px solid #dedede!important;width:100%;height:45px;padding:0 35px 0 10px;color:#000;font-size:16px;border-radius:5px;box-sizing:border-box}.search-field[data-v-5db197e2]:-ms-input-placeholder{color:#000;filter:alpha(opacity=1);-moz-opacity:1;-khtml-opacity:1;opacity:1}.search-field[data-v-5db197e2]::-moz-placeholder{color:#000;filter:alpha(opacity=1);-moz-opacity:1;-khtml-opacity:1;opacity:1}.search-field[data-v-5db197e2]::placeholder{color:#000;filter:alpha(opacity=1);-moz-opacity:1;-khtml-opacity:1;opacity:1}.search-field[data-v-5db197e2]:focus{outline:none}.filter-alphabet[data-v-5db197e2]{display:flex}@media(max-width:1023.9px){.filter-alphabet[data-v-5db197e2]{display:none}}.filter-alphabet span[data-v-5db197e2]{margin:0 5px;font-size:20px;margin-right:20px}.filter-alphabet span.alpha[data-v-5db197e2]{margin:-.1rem 0 0 0;width:30px;height:30px;display:flex;align-items:center;justify-content:center;cursor:pointer;text-decoration:none;text-transform:uppercase;color:#1a0dab;border:1px solid #fff;border-radius:2px;transition:all .6s cubic-bezier(.22,1,.36,1);box-sizing:border-box}.filter-alphabet span.alpha[data-v-5db197e2]:hover{border:1px solid #1a0dab}.filter-alphabet span.alpha.--active[data-v-5db197e2]{background:#e8e6f6}", ""]);
// Exports
module.exports = exports;
