import { createStore } from 'vuex';

export default createStore({
    state: {
        apiUrl: 'https://api.eurococ.eu/cocapi.php/',
        apiProductList: 'product/list/',
        currentProductCategory: {},
        productsRaw: [],
        productTypes: ['PKW', 'LKW', 'MOTO'],
        products: { PKW: [], LKW: [], MOTO: [] },
        alphabets: { PKW: [], LKW: [], MOTO: [] },
        productSlice: 8,
        productSliceStep: 8,
        filterAlphabet: '',
        filterSearch: '',
        brandPage: false,
        typePage: false,
        detailPage: false,
    },
    mutations: {
        setProductCategory(state, cat) {
            state.currentProductCategory = cat;
        },
        setProducts(state, response) {
            state.products[response.type].push(response);
        },
        setAlphabets(state, payload) {
            state.alphabets[payload.key].push(payload.value);
        },
        setProductSlice(state, slice) {
            state.productSlice = slice;
        },
        setFilterAlphabet(state, alpha) {
            state.filterAlphabet = alpha;
        },
        setFilterSearch(state, string) {
            state.filterSearch = string;
        },
        setBrandPage(state, payload) {
            state.brandPage = payload;
        },
        setTypePage(state, payload) {
            state.typePage = payload;
        },
        setDetailPage(state, payload) {
            state.detailPage = payload;
        },
        setProductsRaw(state, payload) {
            state.productsRaw = payload;
        },
    },
    actions: {},
    modules: {},
});
