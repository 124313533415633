import { createApp } from 'vue';
import moment from 'moment';
import App from './App.vue';
import store from './store';
import globalMixins from './mixins/globalMixins.js';
import { setupI18n } from './i18n';
import '@/scss/app.scss';

let langs = ['en', 'fr', 'de', 'es', 'it'];

//zoberiem lang z url
let defLang = window.defaultLang ? window.defaultLang : 'en';

let lang =
    window.location.href.split('/')[3].length > 1 && langs.includes(window.location.href.split('/')[3])
        ? window.location.href.split('/')[3]
        : defLang;

moment.locale(lang);

//nastavim lang do i18n
const i18n = setupI18n(lang);

let app = createApp(App);

app.config.globalProperties.$moment = moment;

app.use(store)
    .mixin(globalMixins)
    .use(i18n)
    .mount('#app');
